import React, { useEffect, useMemo, useState } from "react";
import { useClaims, useRootStore } from "../hooks";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { removeTrailingSlash } from "../helpers/pathname";
import { TopAppBar } from "../components/modules/layouts/topAppBar/TopAppBar";
import LeftAppDrawer from "../components/modules/layouts/leftAppDrawer/LeftAppDrawer";
import { Alert, Layout, LayoutContent } from "../components/uiKit";
import CompanySettingsOnFirstLoginDialog from "../components/modules/dialogs/companySettingsOnFirstLoginDialog/CompanySettingsOnFirstLoginDialog";
import EmployeeSettingsOnFirstLoginDialog from "../components/modules/dialogs/employeeSettingsOnFirstLoginDialog/EmployeeSettingsOnFirstLoginDialog";
import VideoDialog from "../components/modules/dialogs/videoDialog/VideoDialog";
import { IssueUpdateWrapper } from "../components/service/issueUpdateWraper/IssueUpdateWrapper";
import { CommonDialogWrapperByQP } from "../components/service/commonDialogWrapperByQP/CommonDialogWrapperByQP";
import { useTranslation } from "react-i18next";
import { GlobalAuthorizedContextProvider } from "../contexts/globalAuthorizedContext";
import { GuideDrawer } from "../components/modules/drawers/guide/GuideDrawer";
import { ShowUpdateNoteDialog } from "../components/modules/dialogs/showUpdateNoteDialog/ShowUpdateNoteDialog";
import { TimezoneNotificationDialog } from "../components/modules/dialogs/timezoneNotificationDialog/TimezoneNotificationDialog";
import ReactGA from "react-ga4";
import { CommunicationModeKey } from "../components/modules/pages/communications/constants/keys";
import { EndAccessTimeDialog } from "../components/modules/dialogs/endAccessTimeDialog/EndAccessTimeDialog";
import { TariffType } from "../api/models/CompanyForAdminDto";
import { permissionKeys } from "../utils/permissions";
import { useUserNameFormat } from "../hooks/useUserNameFormat";
import "./authorized.scss";

interface IAuthorizedLayout {
  children: React.ReactNode;
  pageKey?: string;
}

function AuthorizedLayout(props: IAuthorizedLayout) {
  const claims = useClaims();
  const { t } = useTranslation();
  const { authStore, appStore, communicationStore } = useRootStore();
  const { getFullName } = useUserNameFormat();

  const currentCompany = authStore.getCurrentCompany;
  const currentUser = authStore.getInitialInfo?.identity;

  const fullName = getFullName(
    currentUser?.lastName ?? "",
    currentUser?.firstName ?? "",
    currentUser?.middleName ?? ""
  );

  const clientName = fullName ?? t("ui:placeholder.new_employee", {id: currentUser?.id ?? 0}) ?? ""

  const [isShowEndAccessTimeDialog, setIsShowEndAccessTimeDialog] = useState<boolean>(false);
  const [isResident, setIsSResident] = useState<boolean>(false);
  const [isResidentEndAccessTime, setIsResidentEndAccessTime] = useState<boolean>(false);


  const getShowEndAccessTimeDialog = () => {
    const { endAccessTime, tariffCode } = currentCompany ?? {};

    if (tariffCode !== TariffType.Demo && tariffCode !== TariffType.Bos && tariffCode !== TariffType.ConsultantSchool) {
      const isOwner = currentCompany?.userOwner?.id ===  currentUser?.id;

      if (endAccessTime && (isOwner || claims.has(permissionKeys.user.edit) || claims.has(permissionKeys.orgchart.edit))) {
        setIsShowEndAccessTimeDialog(true);
        setIsResidentEndAccessTime(endAccessTime ? new Date(endAccessTime) <= new Date() : false);
        setIsSResident(true);
      }
    } else {
      setIsShowEndAccessTimeDialog(endAccessTime ? new Date(endAccessTime) <= new Date() : false);
    }
  }

  const handleCloseEndAccessTimeDialog = () => {
    setIsShowEndAccessTimeDialog(false);
    setIsSResident(false);
    setIsResidentEndAccessTime(false);
  }

  useEffect(() => {
    if (authStore.isAuthorized) {
      getShowEndAccessTimeDialog();
    }
  }, [authStore.isAuthorized]);


  const navigate = useNavigate();
  // const isAdBlockDetected = useDetectAdBlock();

  const setUserGAData = () => {
    if (
      window.location?.hostname == "localhost" ||
      window.location?.hostname == "beta.tonnus.io" ||
      window.location?.hostname == "rc.tonnus.io"
    ) {
      return;
    }
    ReactGA.set({
      user_properties: {
        companyId: authStore.getCurrentCompanyId,
        companyName: authStore.getCurrentCompany?.name?.trim()?.length
          ? authStore.getCurrentCompany?.name?.trim()
          : undefined,
        // userId: authStore.getInitialInfo?.identity?.id,
      },
    });
    ReactGA.event("login");
  };

  useEffect(() => {
    setUserGAData();
  }, []);

  useEffect(() => {
    if (
      authStore.getInviteCode != null &&
      authStore.getInviteCode.length > 0 &&
      !removeTrailingSlash(window.location.pathname).includes("settings/profile")
    ) {
      navigate("/settings/profile");
    }
  }, []);

  const isShowBetaAlert = useMemo(() => {
    const currentPage =
      authStore?.getInitialInfo?.menuItems?.find((i) => i.key == props.pageKey);

    return currentPage?.versionType != null && currentPage?.versionType == "alpha"
      || currentPage?.versionType == "beta"
  }, [authStore?.getInitialInfo?.menuItems, communicationStore.getMode, props.pageKey]);

  return (
    <GlobalAuthorizedContextProvider>
      <TopAppBar />
      <CommonDialogWrapperByQP />
      <IssueUpdateWrapper />
      <CompanySettingsOnFirstLoginDialog />
      <EmployeeSettingsOnFirstLoginDialog />
      <GuideDrawer />
      <ShowUpdateNoteDialog />
      <TimezoneNotificationDialog />
      {authStore.isAuthorized && (
        <>
          {isShowEndAccessTimeDialog && (
            <EndAccessTimeDialog
              endAccessTime={currentCompany?.endAccessTime!}
              userOwnerEmail={currentCompany?.userOwner?.email ?? ""}
              isResident={isResident}
              isResidentEndAccessTime={isResidentEndAccessTime}
              clientName={clientName}
              onClose={handleCloseEndAccessTimeDialog}
            />
          )}

          <VideoDialog
            trainingKey="ed.b.s"
            name="profile"
            onWatchedClickObject={{ profile: true }}
            hidden={
              authStore.getInitialInfo?.identity?.name != null &&
              authStore.getInitialInfo?.identity?.name.trim()?.length > 0
            }
          />
          <VideoDialog
            trainingKey="ed.b.o"
            name="overview"
            onWatchedClickObject={{ overview: true }}
            hidden={
              authStore.getInitialInfo?.identity?.name != null &&
              authStore.getInitialInfo?.identity?.name.trim()?.length > 0
            }
          />
          <VideoDialog
            trainingKey="ed.b.i"
            name="start"
            onWatchedClickObject={{ start: true }}
            hidden={
              authStore.getInitialInfo?.identity?.name != null &&
              authStore.getInitialInfo?.identity?.name.trim()?.length > 0
            }
          />
        </>
      )}
      <Layout className={"authorized-layout"} style={{ marginTop: "56px" }}>
        <LeftAppDrawer
          pageKey={props.pageKey}
        />
        <LayoutContent
          className={`app-content__wrapper  app-content__wrapper_${props.pageKey}`}
        >
          {isShowBetaAlert && (
              <Alert type="warning" className="py-1 px-2" message={t("parse:section_under_construction")} />
            )}
          {appStore.getCanDrawMainLayout ? props.children : <div />}
        </LayoutContent>
      </Layout>
      {/*<LayoutFooter className="px-4 py-2" style={{ background: "var(--color-primary-weak)" }}>*/}
      {/*  <div className="d-flex justify-space-between align-center ml-auto mr-auto" style={{ maxWidth: "768px" }}>*/}
      {/*    /!*  <div className="flex-grow-1" />*!/*/}
      {/*    /!*</div>*!/*/}
      {/*    /!*<div>*!/*/}
      {/*    /!*<Text children="We have an App!" />*!/*/}
      {/*    <Button*/}
      {/*      onClick={() => window.open("intent://my.tonnus.io/#Intent;scheme=https;package=io.tonnus;end")}*/}
      {/*      children={"Open the App"}*/}
      {/*    />*/}
      {/*    <NotificationsCenter />*/}
      {/*  </div>*/}
      {/*</LayoutFooter>*/}
    </GlobalAuthorizedContextProvider>
  );
}

export default observer(AuthorizedLayout);
