import React, { useEffect, useRef } from "react";
// Connect Inter Font
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "./styles/global.scss";
import { useLocalStorage, useRootStore } from "./hooks";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
// Import Google Analytics v4
// import GA4React from "ga-4-react";
// Import Carrot Quest Localizations
// Import Dayjs
import dayjs from "dayjs";
import "./services/dayjs";
// import AntD locales
import ru_RU from "antd/lib/locale/ru_RU";
import en_US from "antd/lib/locale/en_US";
import uk_UA from "antd/lib/locale/uk_UA";
import zh_TW from "antd/lib/locale/zh_TW";
import es_ES from "antd/lib/locale/es_ES";
import pl_PL from "antd/lib/locale/pl_PL";
import de_DE from "antd/lib/locale/de_DE";


// Import all required components
import { App as AppWrapper, ConfigProvider, theme } from "antd";
import { Layout } from "./components/uiKit";
import { ConnectionChecker } from "./components/service/connectionChecker/ConnectionChecker";
import { AppPreloader } from "./components/service/appPreloader/AppPreloader";
import AppRouter from "./components/service/appRouter/AppRouter";
import ImageViewer from "./components/service/imageViewer/ImageViewer";
import FileViewer from "./components/service/fileViewer/FileViewer";
// Import all required DTOs
import { UserDto } from "./api";
import { Locale } from "antd/es/locale";
import {
  carrotAdditionalSettingsEn,
  carrotAdditionalSettingsRu,
  carrotAdditionalSettingsUk,
  carrotAdditionalSettingsUzCyrl,
  carrotAdditionalSettingsUzLatan,
  carrotAdditionalSettingsZh,
  carrotAdditionalSettingsDe,
  carrotAdditionalSettingsPl,
  carrotAdditionalSettingsEs,
  carrotQuestLocaleEn,
  carrotQuestLocaleRu,
  carrotQuestLocaleUk,
  carrotQuestLocaleUzCyrl,
  carrotQuestLocaleUzLatan,
  carrotQuestLocaleZh,
  carrotQuestLocaleDe,
  carrotQuestLocalePl,
  carrotQuestLocaleEs,
} from "./locales/service/carrot-quest";
import { RootStoreProvider } from "./contexts/storeContext";
import { ISchedulePageExpandedRolesStateItem } from "./components/pages/schedule/SchedulePage";
import { PlaceholderTechnicalWork } from "./components/uiKit/placeholderTechnicalWork/PlaceholderTechnicalWork";
import { useUserNameFormat } from "./hooks/useUserNameFormat";
// Import DayJS locales
require("dayjs/locale/ru");
require("dayjs/locale/uk");
require("dayjs/locale/en");
require("dayjs/locale/zh-tw");
require("dayjs/locale/zh-tw");
require("dayjs/locale/uz");
require("dayjs/locale/uz-latn");
require("dayjs/locale/de");
require("dayjs/locale/pl");

declare global {
  interface Window {
    carrotquest: any;
  }
}

function AppObserved() {
  const { appStore, authStore } = useRootStore();
  const { getUserShortFullName } = useUserNameFormat();
  const { defaultAlgorithm, darkAlgorithm } = theme;

  const identityFallback = useRef<UserDto | null>(null);
  const isCarrotQuestInitialized = useRef<boolean>(false);
  const antDLocale = useRef<Locale>(en_US);

  const appSetLocaleDayjsAntd = (localeCode: string) => {
    // dayjs.tz.setDefault("America/New_York");
    dayjs.locale(localeCode == "zh" ? "zh-tw" : localeCode);
    if (localeCode == "ru") antDLocale.current = ru_RU;
    if (localeCode == "en") antDLocale.current = en_US;
    if (localeCode == "uk") antDLocale.current = uk_UA;
    if (localeCode == "zh") antDLocale.current = zh_TW;
    if (localeCode == "es") antDLocale.current = es_ES;
    if (localeCode == "es") antDLocale.current = es_ES;
    if (localeCode == "de") antDLocale.current = de_DE;
    if (localeCode == "pl") antDLocale.current = pl_PL;


    if (localeCode.includes("uz")) antDLocale.current = ru_RU;

    document.body.setAttribute("lang", localeCode);
    dayjsSetStartOfWeek(localeCode);
  };

  const dayjsSetStartOfWeek = (localeCode?: string) => {
    const weekStart = authStore.getInitialInfo?.identity?.companies?.find(
      (u2c) => u2c.companyId == authStore.getCurrentCompanyId
    )?.company?.weekStart;
    weekStart != null &&
      dayjs.updateLocale((localeCode == "zh" ? "zh-tw" : localeCode) ?? dayjs.locale(), {
        weekStart: weekStart,
      });
  };

  const carrotAdditionalSetting = (localeCode: string) => {
    switch (localeCode) {
      case "en":
        return {
          locFile: carrotQuestLocaleEn,
          locFileAdditional: carrotAdditionalSettingsEn,
        }
      case "ru":
        return {
          locFile: carrotQuestLocaleRu,
          locFileAdditional: carrotAdditionalSettingsRu,
        }
      case "uk":
        return {
          locFile: carrotQuestLocaleUk,
          locFileAdditional: carrotAdditionalSettingsUk,
        }
      case "de":
        return {
          locFile: carrotQuestLocaleDe,
          locFileAdditional: carrotAdditionalSettingsDe,
        }
      case "es":
        return {
          locFile: carrotQuestLocaleEs,
          locFileAdditional: carrotAdditionalSettingsEs,
        }
      case "pl":
        return {
          locFile: carrotQuestLocalePl,
          locFileAdditional: carrotAdditionalSettingsPl,
        }
      case "zh":
        return {
          locFile: carrotQuestLocaleZh,
          locFileAdditional: carrotAdditionalSettingsZh,
        }
      case "uz-cyrl":
        return {
          locFile: carrotQuestLocaleUzCyrl,
          locFileAdditional: carrotAdditionalSettingsUzCyrl,
        }
      case "uz-latan":
        return {
          locFile: carrotQuestLocaleUzLatan,
          locFileAdditional: carrotAdditionalSettingsUzLatan,
        }
      default:
        return {
          locFile: carrotQuestLocaleEn,
          locFileAdditional: carrotAdditionalSettingsEn,
        }
    }
  }

  const carrotQuestSetLocale = (localeCode: string, isInitialized: boolean) => {
    if (!isInitialized) return;
    window.carrotquest && window.carrotquest.removeChat && window.carrotquest.removeChat();
    const { locFile, locFileAdditional } = carrotAdditionalSetting(localeCode);
        window.carrotquest.i18n = locFile;
    window.carrotquest.data.app.settings = {
      ...window.carrotquest.data.app.settings,
      ...locFileAdditional,
    };
  };

  const carrotQuestInit = () => {
    const identity = toJS(authStore.getInitialInfo)?.identity;
    if (identity != null && identity != identityFallback.current) {
      const userName = getUserShortFullName(
        identity?.lastName ?? "",
        identity?.firstName ?? "",
        identity?.middleName ?? ""
      ) ;
      const userEmail = identity.email ?? "";
      const userPhoneNumber = identity.phoneNumber ?? "";
      const currentCompanyName =
        ((identity.companies?.find((ic) => ic.companyId == identity.currentCompanyId)?.company?.name ?? "").trim()
          .length > 0
          ? identity.companies?.find((ic) => ic.companyId == identity.currentCompanyId)?.company?.name ?? ""
          : identity.companies?.find((ic) => ic.companyId == identity.currentCompanyId)?.company?.nameFallback) ?? "";

      window.carrotquest &&
        window.carrotquest.identify({
          $name: userName + " (" + currentCompanyName + ")",
          $email: userEmail,
          $phone: userPhoneNumber,
        });

      identityFallback.current = identity;
    }
  };

  useEffect(() => {
    if (appStore.getAppLocale == null || appStore.getAppLocale.trim().length == 0) return;
    appSetLocaleDayjsAntd(appStore.getAppLocale.trim());
    carrotQuestSetLocale(appStore.getAppLocale.trim(), isCarrotQuestInitialized.current);
  }, [appStore.getAppLocale]);

  useEffect(() => {
    dayjs.tz.setDefault(authStore.getInitialInfo?.identity?.timeZoneId ?? undefined);
  }, [authStore.getInitialInfo?.identity?.timeZoneId]);

  useEffect(() => {
    carrotQuestInit();
    dayjsSetStartOfWeek();
  }, [authStore.getInitialInfo]);

  useEffect(() => {
    console.log("window.opener?.handleCloseGoogleAuthModal", window.opener?.handleCloseGoogleAuthModal);
    if (window.opener?.handleCloseGoogleAuthModal) {
      window.opener.handleCloseGoogleAuthModal();
      window.close();
    }
    // @ts-ignore
    window.carrotquest.onReady(() => {
      isCarrotQuestInitialized.current = true;
      appStore.getAppLocale != null &&
        appStore.getAppLocale.trim().length > 0 &&
        carrotQuestSetLocale(appStore.getAppLocale.trim(), isCarrotQuestInitialized.current);
    });

  }, []);

  // Initialize Google Analytics
  // try {
  //   setTimeout((_) => {
  //     const ga4react = new GA4React("G-P9WJMQHY48");
  //     ga4react.initialize().catch((err) => console.error(err));
  //   }, 500);
  // } catch (err) {
  //   console.error(err);
  // }

  const [passwordTestingServer, setPasswordTestingServer] = useLocalStorage<string>("passwordTestingServer","")

  const passwordVerificationTestingServer = () => {
    const password = prompt("Введите пароль", "");
    if (password !== '13leet37') {
      passwordVerificationTestingServer();
    } else {
      setPasswordTestingServer(password);
    }
  }


  useEffect(() => {
    const isDev =
      window.location?.hostname === "dev.bbooster.io" ||
      window.location.hostname === "staging.bbooster.io";
    if (!passwordTestingServer && isDev) {
      passwordVerificationTestingServer();
    }
  }, []);

  const isShowPlaceholderTechnicalWork = false;

  return isShowPlaceholderTechnicalWork ? (
        <PlaceholderTechnicalWork/>
      ) : (
        <ConfigProvider
          locale={antDLocale.current}
          theme={{
            algorithm: appStore.getAppTheme == 1 ? darkAlgorithm : defaultAlgorithm,
            token: {
              colorPrimary: appStore.getAppTheme == 1 ? "#788EFF" : "#5672FF",
              colorInfo: appStore.getAppTheme == 1 ? "#788EFF" : "#5672FF",
              colorError: appStore.getAppTheme == 1 ? "#F04438" : "#F04438",
              colorSuccess: appStore.getAppTheme == 1 ? "#12B76A" : "#12B76A",
              colorWarning: appStore.getAppTheme == 1 ? "#EAAA08" : "#EAAA08",
              colorTextSecondary: appStore.getAppTheme == 1 ? "#98A2B3" : "#98A2B3",
              colorIcon: appStore.getAppTheme == 1 ? "#98A2B3" : "#98A2B3",
              colorText: appStore.getAppTheme == 1 ? "#FFFFFF" : "#222A38",
              colorTextBase: appStore.getAppTheme == 1 ? "#FFFFFF" : "#222A38",
              colorTextPlaceholder: appStore.getAppTheme == 1 ? "#98A2B3" : "#98A2B3",
              colorBgContainer: appStore.getAppTheme == 1 ? "#222A38" : "#FFFFFF",
              colorBorder: appStore.getAppTheme == 1 ? "#475467" : "#E4E7EC",
              fontFamily:
                "-apple-system, BlinkMacSystemFont, Inter, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
            },
          }}
        >
          <AppWrapper>
            <RootStoreProvider>
              <Layout id="appLayout" className="app-layout__wrapper">
                <ConnectionChecker />
                <ImageViewer />
                <FileViewer />
                <AppPreloader />
                <AppRouter />
                {/*<div id="notification__wrapper">*/}
                {/*  <div id="notification__inner" />*/}
                {/*</div>*/}
              </Layout>
            </RootStoreProvider>
          </AppWrapper>
        </ConfigProvider>
      )

}

export const App = observer(AppObserved);
